<template>
  <div v-if="contentPage">
    <section class="product-list-heading mb-0">
      <div class="container">
        <div class="breadcrumb pt-lg-5">
          <router-link to="/">Home</router-link>
          <i class="icon-next"></i>
          <router-link to="/lizmagz">Liz-Magz</router-link>
          <i class="icon-next"></i>
          <a href="#">{{ contentPage.meta_title }}</a>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-8">
            <!-- Article Title -->
            <h1 class="magz-title text-center">{{ contentPage.meta_title }}</h1>
            <!-- Article Date -->
            <p class="roboto12 text-center">Posted on <b>{{ formatDate(contentPage.created_at_str) }}</b></p>
            <!-- Article Image -->
            <img
              :src="contentPage.image_url"
              :alt="contentPage?.image_alternate || ''"
              @error="replaceByDefaultImage"
              class="w-100"
            />
            <!-- Article Content -->
            <div id="render-article" class="mt-2"></div>
            <!-- Share Social Media -->
            <div class="mt-2 text-center">
              <a :href="setUrlShare('https://www.facebook.com/sharer/sharer.php?u=')">
                <img src="@/assets/img/facebook-1.png" alt="Share Facebook" />
              </a>
              <a :href="setUrlShare('https://twitter.com/intent/tweet?text=')">
                <img src="@/assets/img/twitter-1.png" alt="Share Twitter" />
              </a>
              <a :href="setUrlShare('https://wa.me/?text=')">
                <img src="@/assets/img/whatsapp-sm.png" alt="Share WhatsApp" />
              </a>
              <a :href="setUrlShare('https://line.me/R/msg/text/?')">
                <img src="@/assets/img/line-sm.png" alt="Share Line" />
              </a>
            </div>
            <!-- Article Comment -->
            <div v-if="!isLoggedIn" class="comment-container custom-font-grey my-3 p-3">
              Please <a href="javascript:void(0)" v-b-modal.modalLogin>login</a> first to to post discussion & reply discussion
            </div>
            <template v-else>
              <b-form-textarea
                ref="newComment"
                rows="2"
                max-rows="50"
                class="mt-2 overflow-hidden"
                placeholder="What do you want to discuss for this article?"
                v-model.trim="newComment"
                no-resize
              />
              <b-button class="btn-wz-primary float-right mt-2" @click="submitNewArticleComment">Send</b-button>
              <div class="clearfix"></div>
            </template>
            <div class="my-3">
              <b>Discussion<span v-if="articleCommentList.length > 0">{{ `(${articleCommentList.length})` }}</span>:</b>
              <div class="comment-container custom-font-grey cursor-pointer my-3 p-3" @click="$refs.newComment.focus()" v-if="articleCommentList.length == 0 && isLoggedIn">
                There are no discussions yet, be the first to have a discussion
              </div>
              <div class="comment-container mt-2" v-for="(comment, indexArticle) in articleCommentList"  :key="indexArticle">
                <!-- Comment -->
                <div class="topic-comment text-break" :style="comment.reply_list.length > 0 || isLoggedIn ? {'--borderRadius' : '8px 8px 0 0'} : ''">
                  <div class="commenter-img-profile" :class="setProfileBgImage(comment.type)">
                    <img src="@/assets/img/whizliz-logo.png" alt="" v-if="comment.type == 'Admin'">
                    <span v-else>{{ formatDialogName(comment.commenter_name) }}</span>
                  </div>
                  <div class="ml-2 d-flex flex-column">
                    <div class="d-flex align-items-end">
                      <b class="font-14px">{{ comment.commenter_name }}</b> 
                      <div class="comment-user-type bg-gold ml-1" v-if="comment.type == 'Admin'">Admin</div> 
                      <div class="comment-user-type user-self-badge ml-1" v-else-if="comment.type == 'Self'">You</div> 
                      <div class="comment-time ml-1">{{ formatCommentDate(comment.created_epoch) }}</div>
                    </div>
                    <div class="comment-text text-break">{{ comment.comment_text }}</div>
                  </div>
                </div>
                <!-- Comment Reply List -->
                <div class="reply-comment" v-if="comment.reply_list.length > 0 || isLoggedIn">
                  <template v-if="comment.has_reply">
                    <!-- TODO if want to implement see all replies feature START -->
                    <!-- <div class="cursor-pointer font-14px mb-2" @click="getArticleReplyComment(comment)" v-if="comment.reply_list.length == 0">
                      See all comments
                    </div> 
                    <template v-else-if="comment.reply_list.length > 0"> -->
                    <!-- TODO if want to implement see all replies feature END -->
                    <template>
                      <div class="d-flex" :class="{ 'mt-3' : indexReply > 0 }" v-for="(reply, indexReply) in comment.reply_list" :key="indexReply">
                        <div class="commenter-img-profile" :class="setProfileBgImage(reply.type)">
                          <img src="@/assets/img/whizliz-logo.png" alt="Whizliz Logo" v-if="reply.type == 'Admin'">
                          <span v-else>{{ formatDialogName(reply.commenter_name) }}</span>
                        </div>
                        <div class="ml-2 d-flex flex-column">
                          <div class="d-flex align-items-end">
                            <b class="font-14px">{{ reply.commenter_name }}</b> 
                            <div class="comment-user-type bg-gold ml-1" v-if="reply.type == 'Admin'">Admin</div> 
                            <div class="comment-user-type user-self-badge ml-1" v-else-if="reply.type == 'Self'">You</div> 
                            <div class="comment-time ml-1">{{ formatCommentDate(reply.created_epoch) }}</div>
                          </div>
                          <div class="comment-text text-break">{{ reply.comment_text }}</div>
                        </div>
                      </div>
                    </template>
                  </template>
                  <div class="d-flex" :class="{ 'mt-3' : comment.has_reply && comment.reply_list && comment.reply_list.length > 0 }" v-if="isLoggedIn">
                    <!-- Submit Comment Reply -->
                    <div class="commenter-img-profile" :class="setProfileBgImage('Self')">
                      {{ formatDialogName(userData.full_name) }}
                    </div>
                    <b-form-textarea 
                      :id="`reply-comment-${comment.id}`"
                      rows="1"
                      max-rows="50"
                      class="ml-2 overflow-hidden"
                      placeholder="Reply discussion..."
                      no-resize
                      @keydown="handleTyping($event, comment.id)"
                    ></b-form-textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { formatter } from "@/_helpers";
import { setMetainfo } from "@/_helpers";
import Vue from "vue";
const StaticCarousel = () => import('@/components/statics/StaticCarousel');

export default {
  name: "MagazinePage",
  metaInfo() {
    return setMetainfo(this.notFound ? "Page not found" : this.contentPage?.title, this.notFound ? "Not found" : this.contentPage?.meta_description)
  },
  data() {
    return {
      contentPage: null,
      notFound: null,
      newComment: "",
      articleCommentList: [],
    };
  },
  watch: {
    $route() {
      this.notFound = null;
      this.getDetailArticle();
    },
    isLoggedIn() {
      this.getDetailArticle();
    },
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
    apiKey() {
      return process.env.VUE_APP_API_KEY;
    },
    feUrl() {
      return process.env.VUE_APP_FE_URL;
    },
    isLoggedIn() {
      return this.$store.getters.getIsLoggedIn;
    },
    userData() {
      return this.$store.getters.getUserData;
    }
  },
  mounted() {
    this.getDetailArticle();
  },
  methods: {
    setProfileBgImage(type) {
      if (!type) return
      else if (type == "Self") return "bg-user-self"
      else if (type == "Other Customer") return "bg-user-other"
    },
    async getDetailArticle() {
      try {
        const res = await this.$api.user.getDetailArticle(this.$route.params.slug)
        if (res.status === 200) {
          this.setDetailArticlePageData(res.data.data);
          this.setArticleComment(res.data.data.comments);
          this.notFound = false;
          // if (this.isLoggedIn) this.getArticleComment(res.data.data.id);
        }
      } catch (e) {
        this.notFound = true;
        this.contentPage = null;
        console.error(e)
      }
    },
    setDetailArticlePageData(data) {
      this.contentPage = data;
      setTimeout(() => {
        this.resetRenderWindow()
        const doc = document.createElement("textarea");
        doc.innerHTML = data.content;
        let template = `<div>${doc.value}</div>`
          .replace(/\[\w+=(\d+)\]/g, `<StaticCarousel productListId="$1" />`)
          .replace(/\n/g, "")
          .trim();
        Vue.component("StaticCarousel", StaticCarousel);
        new Vue({
          el: "#content-web",
          store: this.$store,
          router: this.$router,
          render: (h) => h(Vue.compile(template)),
        });
      }, 500);
    },
    resetRenderWindow() {
      const renderArticle = document.getElementById('render-article')
      renderArticle.innerHTML = ''
      const web = document.createElement("div")
      web.setAttribute("id", "content-web")
      renderArticle.appendChild(web)
    },
    async setArticleComment(data) {
      // TODO if want to implement see all replies feature
      // if (this.articleCommentList.length == 0) this.articleCommentList = data.map(el => ({ ...el, reply_list: [] }))
      // else {
      //   for await (let comment of this.articleCommentList) {
      //     if(comment.reply_list.length > 0) {
      //       const res = await this.$api.user.getArticleReplyComment(comment.id)
      //       if(res.status === 200) comment.reply_list = res.data.data
      //     }
      //   }
      // }
      this.articleCommentList = data.map(el => ({ ...el, reply_list: [] }))
      for await (let comment of this.articleCommentList) {
        const res = await this.$api.user.getArticleReplyComment(comment.id)
        if(res.status === 200) comment.reply_list = res.data.data
      }
      setTimeout(() => {
        window.prerenderReady = true
      }, 3000)
    },
    // Method to get comment list with API to GET Article Comment
    // async getArticleComment(articleId) {
    //   try {
    //     const res = await this.$api.user.getArticleComment(articleId);
    //     if (res.status === 200) {
    //       this.articleCommentList = res.data.data
    //       for await (let comment of this.articleCommentList) {
    //         if(comment.has_reply) {
    //           const res = await this.$api.user.getArticleReplyComment(comment.id)
    //           if(res.status === 200) comment.reply_list = res.data.data
    //         }
    //       }
    //     }
    //   } catch (e) {
    //     console.error(e)
    //   }
    // },
    async getArticleReplyComment(comment){
      try {
        const res = await this.$api.user.getArticleReplyComment(comment.id)
        if(res.status === 200) comment.reply_list = res.data.data
      } catch (e) {
        console.error(e)
      }
    },
    async submitNewArticleComment() {
      if(!this.newComment) return 
      const body = {
        article_id : this.contentPage.id,
        comment : this.newComment
      }
      const res = await this.$api.user.submitArticleComment(body)
      if(res.status === 200) {
        this.newComment = ""
        this.getDetailArticle();
      }
    },
    async submitReply(comment, commentId) {
      try {
        const body = {
          article_id : this.contentPage.id,
          comment : comment,
          reply_to : commentId
        }
        const res = await this.$api.user.submitArticleComment(body)
        if(res.status === 200) {
          this.getDetailArticle();
          // Clear reply comment textarea
          const el = document.getElementById(`reply-comment-${commentId}`)
          el.value = ""
        }
      } catch (e) {
        console.error(e)
      }
    },
    setUrlShare(baseUrl){
      return baseUrl + this.feUrl + this.$route.path.substring(1)
    },
    handleTyping(e, commentId) {
      if (e.keyCode == 13 && !e.shiftKey) {
        e.preventDefault();
        if(e.target.value.trim()) this.submitReply(e.target.value.trim(), commentId);
      }
    },
    formatDialogName(name) {
      if(!name) return
      const nameArr = name.split(" ");
      var result = "";
      for (let i = 0; i < nameArr.length; i++) result += nameArr[i].charAt(0);
      return result.substr(0, 2).toUpperCase();
    },
    formatDate(value) {
      return formatter.date(value, "D MMMM YYYY")
    },
    formatCommentDate(value) {
      return formatter.todayYesterday(value, "DD MMMM YYYY")
    },
    replaceByDefaultImage(img) {
      img.target.src = require("@/assets/img/product-default.png");
    },
  },
};
</script>

<style scoped>
div.social-media a img {
  padding-right: 10px;
  scale: 1.1;
}
span >>> p {
  text-align: justify;
  line-height: 180%;
}
span >>> img {
  height: auto !important;
}
/* Article Comment Start */
.custom-font-grey {
  color: #757575;
}
.commenter-img-profile {
  color: #FFFFFF;
}
.bg-user-self {
  background: #9BA348;
}
.bg-user-other {
  background: #c67602;
}
/* Article Comment End */
</style>